<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-select placeholder="状态" v-model="query.status" clearable>
          <i-option value="-1">已废弃</i-option>
          <i-option value="0">草稿</i-option>
          <i-option value="4">待审批</i-option>
          <i-option value="5">已通过</i-option>
          <i-option value="6">已拒绝</i-option>
          <i-option value="7">已完成</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-input  placeholder="关键字：客户名称、投放品牌、备注" v-model="query.keyword">
        </i-input>
      </i-col>
       <i-col :xs="24" :sm="12" :md="6"  :lg="4" class="m-b-5">
        <i-button class="m-r-10" type="primary" icon="ios-search" long @click="handleSearch">搜索</i-button>
        <!-- <i-button  icon="md-cloud-download" type="info" >导出Excel</i-button> -->
      </i-col>
    </Row>

    <!-- 变更列表 -->
  <Table
      stripe
      :data="list"
      :columns="tableColumns"
    ></Table>
    <!-- <i-table stripe :data="list" :columns="titles"></i-table> -->
    <div class="paging_style">
      <Page size="small" :page-size="query.pageSize" :total="total" @on-change="handlePageChanged" show-total show-elevator></Page>
    </div>

  </div>
</template>

<script>
import { confirmationPage } from '@/api/order/confirmation'
import { GetCurrentSchedule } from '@/utils/dateFormat'

export default {
  data () {
    return {
      total: 0,
      list: [],
      query: { pageNumber: 1, pageSize: 15, status: null, keyword: '' },
      tableColumns: [
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '发布品牌',
          key: 'brandName'
        },
        {
          title: '起止日期',
          key: 'name',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                this.formatSchuedle(params.row)
              )
            ])
          }
        },
        {
          title: '备注',
          key: 'desc'
        },
        {
          title: '状态',
          key: 'statusName',
          render: (h, params) => {
            const color = this.formatContractStautsColor(params.row.status)
            return h('div', [
              h(
                'Tag',
                {
                  props: {
                    color: color
                  }

                },
                params.row.statusName
              )
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleDetail(params.row.confirmationId)
                    }
                  }
                }, '详情'
              )
            ])
          }
        }
      ]
    }
  },
  created () {
    this.initPage()
  },
  methods: {
    initPage () {
      this.getAgreementList()
    },
    getAgreementList () {
      confirmationPage(this.query).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    handleDetail (agreementId) {
      this.$emit('on-showDetail', agreementId)
    },

    handlePageChanged (page) {
      this.query.pageNumber = page
      this.getAgreementList()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getAgreementList()
    },
    formatSchuedle (data) {
      return GetCurrentSchedule(data.startDate, data.endDate)
    },
    formatContractStautsColor (status) {
      let color = 'Gray'
      if (status === 0) { // 草稿
        color = 'default'
      } else if (status === 3) { // 创建合同中
        color = 'SeaGreen'
      } else if (status === 4) { // 待审批
        color = 'Orange'
      } else if (status === 5) { // 已通过
        color = 'SeaGreen'
      } else if (status === 6) { // 已拒绝
        color = '#ed4014'
      } else if (status === 9) { // 已归档
        color = 'Black'
      }

      return color
    }

  }
}
</script>
